import { persistor, sendRoomEventSafe } from "playground-core";
import { debugPanelEvent, log } from "playground-core";
import { showPrompt } from "./components/dialog/PromptDialog";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import React, { Suspense } from "react";
import { RecoilRoot } from "recoil";
import "./utils/store";
import { LoadingScreen } from "./components/splash-screen/LoadingScreen";
import { LogoAnimation } from "./components/splash-screen/LogoAnimation";

export const Main = React.lazy(() => import("./components/core/MainLayout"));
export const DockLayout = React.lazy(
  () => import("./components/dock/OBSDockLayout")
);
import { reduxStore } from "playground-core";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ConsentOption } from 'playground-data-model'

function MainProxy() {
  return (
    <>
      <Suspense fallback={<LoadingScreen />}>
        <Main />
      </Suspense>
      {/* live2d licence */}
      {/* <LogoAnimation /> */}
    </>
  );
}

function DockLayoutProxy() {
  return (
    <Suspense fallback={<>Loading</>}>
      <DockLayout />
    </Suspense>
  );
}

export function App() {
  return (
    <Provider store={reduxStore}>
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        <RecoilRoot>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MainProxy />}>
                <Route path="/room/:id" element={<MainProxy />} />
                <Route path="/:dev" element={<MainProxy />} />
                <Route path="/:dev/room/:id" element={<MainProxy />} />
              </Route>
              <Route path="/dock" element={<DockLayoutProxy />}></Route>
            </Routes>
          </BrowserRouter>
        </RecoilRoot>
      </PersistGate>
    </Provider>
  );
}

window.addEventListener("unload", function asd() {
  sendRoomEventSafe("leaveRoom", { isLeavingRoom: true });
});

window.addEventListener(
  "wheel",
  (ev: WheelEvent) => {
    if (ev.ctrlKey) {
      ev.preventDefault();
    }
  },
  { passive: false }
);

debugPanelEvent.on("request-consent", async (options: ConsentOption) => {
  const result = await showPrompt({
    eventType: "request-consent",
    title: "Consent",
    message: "Accept " + options.nonce + "?",
    hideOnTapOutside: false
  });

  sendRoomEventSafe("acceptConsent_" + options.nonce, {
    accept: result,
    nonce: options.nonce
  } as ConsentOption);
});

export const defaultScaleValue = 0.2;
