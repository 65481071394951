import { Dialog, Transition } from '@headlessui/react'
import {
  Fragment,
  useState,
  useEffect,
  FunctionComponent,
  SetStateAction,
  Dispatch,
  FormEventHandler,
  ReactNode,
} from 'react'
import { debugPanelEvent, events, log, removeItemOnce } from 'playground-core'
import { discordUrl } from '../toolbar/getHelp'
import { Checkbox } from '../common/Checkbox'
import {
  MdOutlineImage,
  MdOutlineUnfoldMore,
  MdOutlineHourglassDisabled,
  MdVisibilityOff as EyeIcon,
  MdVisibility as EyeIcon2,
  MdDragIndicator,
} from 'react-icons/md'
import { uuid4 } from '@sentry/utils'
import { delay } from '../../utils/delay'
import { Button } from '../common/Button'
import { LinkButton } from '../common/LinkButton'
import { FaTwitter } from 'react-icons/fa'
import { EventContext } from '../toolbar/ContextProvider'

type AmplitudeEventComponentProps = {
  eventType?: string
  eventProperty?: Record<string, any> | undefined
}

export type PromptDialogOptions = AmplitudeEventComponentProps & {
  title?: string
  id?: any
  titleSuffixContent?: React.ReactNode
  helpUrl?: string
  showHelp?: boolean
  hideHelp?: boolean
  message?: string
  confirmText?: string | JSX.Element
  cancelText?: string
  onConfirm?: () => void
  onCancel?: () => void
  onClose?: () => void
  onSubmit?: FormEventHandler<HTMLFormElement>
  hideCancel?: boolean
  hideConfirm?: boolean
  hideOnTapOutside?: boolean
  overlayClickTrough?: boolean
  obfuscate?: boolean
  customContent?: FunctionComponent<any> | null
  customJSXContent?: JSX.Element | null
  customCover?: ReactNode | null
  showCheckbox?: boolean
  checkboxGap?: string
  customStyle?: string
  customStyle2?: string
  className?: string
  overrideDialog?: boolean
  showPicture?: boolean
  hideBlackBlock?: boolean
}

let myName: string | null

let promptInputText = ''

function InputPrompt(props: { isPassword: boolean }) {
  const [roomPwd, setRoomPwd] = useState<string>('')
  const [showPwd, setShowPwd] = useState(false)

  const showPasswordType = () => {
    if (!props.isPassword) return 'text'
    if (showPwd) return 'text'
    return 'password'
  }

  return (
    <div className='flex'>
      <input
        role={'submit'}
        className=' w-full mt-2 mb-2 px-3 py-2 rounded-md bg-base-100 bg-opacity-90'
        // style={{
        //   fontFamily: 'Flow Circular'
        // }}
        type={showPasswordType()}
        value={roomPwd}
        onChange={(e) => {
          setRoomPwd(e.target.value)
          promptInputText = e.target.value
        }}
        maxLength={props.isPassword ? 72 : undefined}
      ></input>

      {props.isPassword && (
        <Checkbox
          text=''
          onIcon={<EyeIcon size={16} />}
          checked={showPwd}
          offIcon={<EyeIcon2 size={16} />}
          setChecked={() => {
            setShowPwd((x) => !x)
          }}
          className='-ml-8 absolute self-center flex justify-center !bg-transparent'
        />
      )}
    </div>
  )
}

const withPasswordInputPrompt =
  (Component: FunctionComponent<any>) => (props: any) =>
    <Component {...props} isPassword />

const PasswordInputField = withPasswordInputPrompt(InputPrompt)

export async function showPromptInput(
  options: PromptDialogOptions
): Promise<string> {
  options.customContent = PasswordInputField
  return new Promise<string>((resolve) => {
    function onConfirm() {
      resolve(promptInputText)
      promptInputText = ''
    }
    function onCancel() {
      resolve(promptInputText)
      promptInputText = ''
    }
    events(`${options.eventType}#dialog_open`)
    debugPanelEvent.emit('show-prompt', {
      ...options,
      onConfirm: onConfirm,
      onCancel: onCancel,
    })
  })
}

let queue: string[] = []

export async function hidePrompt() {
  debugPanelEvent.emit('close-prompt')
}

/**
 * @deprecated use {@link module:dialogUtils~showDialog}
 */
export async function showPrompt(
  options: PromptDialogOptions
): Promise<boolean> {
  const id = uuid4()

  const p = new Promise<boolean>(async (resolve) => {
    log('[k] setting up prompt')

    log('[k]Appended to queue')

    if (!options.overrideDialog) {
      queue.push(id)
      if (queue.length > 0) {
        log('[k] waiting for queue to clear')
        log(queue)
        while (queue[0] !== id) {
          await delay(100)
        }
      }
    }

    function onConfirm() {
      options.onConfirm?.()
      resolve(true)
    }
    function onCancel() {
      options.onCancel?.()
      resolve(false)
    }
    function onClose() {
      options.onClose?.()
      resolve(false)
    }
    if (options != null)
      myName = localStorage.getItem(options.eventType + '_do_not_show_again')
    if (myName != null) return resolve(true)
    events(`${options.eventType}#prompt_dialog_open`)
    debugPanelEvent.emit('show-prompt', {
      ...options,
      onConfirm: onConfirm,
      onCancel: onCancel,
      onClose: onClose,
    })
  }).finally(() => {
    removeItemOnce(queue, id)
    log('[k]Removing from queue: ' + queue)
  })
  return p
}

export function BarePromptDialog(props: DialogProps) {
  return (
    <CorePromptDialog
      isOpen
      hideCancel
      hideConfirm
      hideBlackBlock
      // className={}
      customStyle2={props.className}
      {...props}
    ></CorePromptDialog>
  )
}

type DialogProps = PromptDialogOptions & {
  isOpen?: boolean
  setIsOpen?: Dispatch<SetStateAction<boolean>>
  children?: React.ReactNode
}

export function CorePromptDialog(props: DialogProps) {
  const options = props

  const [show, setShow] = useState(false)
  useEffect(() => {
    events(`${options.eventType}#dialog_open`)
  }, [])
  useEffect(() => {
    if (options != null)
      if (show) {
        events(`${options?.eventType}#dialog_hide`)
        localStorage.setItem(options.eventType + '_do_not_show_again', 'true')
      }
  }, [show])

  return (
    <>
      <EventContext.Provider value={options.eventType!}>
        {!props.hideBlackBlock && (
          <div className='z-20 absolute w-full h-full items-center flex rounded-2xl justify-center self-center bg-black bg-opacity-30 pointer-events-none' />
        )}

        <form
          key={props.id}
          autoComplete='off'
          onSubmit={(e) => {
            e.preventDefault()
            options.onSubmit?.(e)
          }}
          className={
            options.customStyle2 +
            ` inline-block w-full sm:w-[448px] p-0 
          text-left transition-all transform
          bg-neutral shadow-xl rounded-2xl bg-opacity-90 pointer-events-auto relative z-50`
          }
        >
          {options.customCover}
          <div className='p-6'>
            <div className='text-lg font-medium leading-6 text-white flex items-center justify-between '>
              <div className='flex'>
                {options?.title}
                {options?.showHelp && (
                  <LinkButton
                    name={'help_link_button'}
                    link={options.helpUrl ?? 'https://www.avatech.gg/faq'}
                  />
                )}
              </div>
              {options.titleSuffixContent}
            </div>

            <div className='flex'>
              <div className='mt-4 w-full'>
                <span className='mt-2 text-sm text-[#C4C4C4]'>
                  {!options?.customContent ? (
                    <p
                      className='mb-4'
                      style={
                        options?.obfuscate
                          ? {
                              fontFamily: 'Flow Circular',
                            }
                          : {}
                      }
                    >
                      {options?.message}
                    </p>
                  ) : (
                    <>
                      {options?.customJSXContent ? (
                        options.customJSXContent
                      ) : (
                        <options.customContent />
                      )}
                    </>
                  )}
                  {props.children}
                </span>
              </div>
            </div>
            <div
              className={`${options?.checkboxGap} flex justify-end gap-4 items-center`}
            >
              {options?.hideCancel ?? (
                <Button
                  as='div'
                  name={`dialog#cancel`}
                  role={'button'}
                  content={options?.cancelText ?? 'Cancel'}
                  className='text-neutral-content !bg-neutral rounded-md
              hover:bg-neutral-100 pointer-events-auto'
                  onClick={(e) => {
                    e.preventDefault()
                    options?.setIsOpen?.(false)
                    options?.onCancel?.()
                  }}
                ></Button>
              )}
              {options?.hideConfirm ?? (
                <Button
                  autoFocus={true}
                  role={'submit'}
                  name={`dialog#confirm`}
                  content={options?.confirmText ?? 'Confirm'}
                  className='px-4'
                  onClick={(e) => {
                    e.preventDefault()
                    options?.setIsOpen?.(false)
                    options?.onConfirm?.()
                  }}
                ></Button>
              )}
              {options?.showCheckbox ? (
                <Checkbox
                  checked={show}
                  setChecked={() => {
                    setShow((x) => !x)
                  }}
                  className='self-center flex justify-center'
                />
              ) : (
                <></>
              )}

              {options?.hideCancel && options?.hideConfirm && (
                <Button
                  content={''}
                  role={'submit'}
                  autoFocus={false}
                  name={`dummy_button`}
                  removeBg
                  className='pointer-events-none focus:outline-none'
                  onClick={(e) => {
                    e.preventDefault()
                    options?.setIsOpen?.(false)
                    options?.onConfirm?.()
                  }}
                ></Button>
              )}
            </div>
          </div>
        </form>
      </EventContext.Provider>
    </>
  )
}

export function BasePromptDialog(props: DialogProps) {
  const options = props

  let [isOpen, setIsOpen] = useState(false)

  if (props.isOpen) isOpen = props.isOpen
  if (props.setIsOpen) setIsOpen = props.setIsOpen

  return (
    <Transition appear show={isOpen} as={Fragment} key={props.id}>
      <Dialog
        id={`${options?.eventType}#prompt_dialog`}
        as='div'
        className={`${options?.className} overflow-y-hidden bg-opacity-90`}
        onClose={() => {
          if (!options?.hideOnTapOutside) return
          setIsOpen(false)
          options?.onCancel?.()
          events(`${options?.eventType}#prompt_dialog_close`)
        }}
      >
        {options?.hideOnTapOutside && (
          <Dialog.Overlay className='fixed inset-0' />
        )}
        <div className={`${options?.customStyle} px-4 text-center`}>
          <span
            className='inline-block h-screen align-middle'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            // as={Fragment}
            className={'h-fit'}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <CorePromptDialog {...options} setIsOpen={setIsOpen} />
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export function PromptDialog() {
  const [options, setOptions] = useState<PromptDialogOptions>({})
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const showPrompt = (options: PromptDialogOptions) => {
      setOptions(options)
      setIsOpen(true)
    }
    const hidePrompt = () => {
      setIsOpen(false)
      options?.onCancel?.()
    }
    debugPanelEvent.on('show-prompt', showPrompt)
    debugPanelEvent.on('close-prompt', hidePrompt)
    return () => {
      debugPanelEvent.removeListener('show-prompt', showPrompt)
      debugPanelEvent.removeListener('close-prompt', hidePrompt)
    }
  })

  const { className, customStyle2, ...otherOptions } = options

  return (
    <BasePromptDialog
      {...otherOptions!}
      className={`${className} fixed inset-0 z-10 min-h-screen `}
      customStyle2={`${customStyle2} my-16`}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    ></BasePromptDialog>
  )
}
