import { events } from 'playground-core'
import React, {
  AllHTMLAttributes,
  createElement,
  ElementType,
  forwardRef,
  FunctionComponent,
  HTMLAttributes,
  useContext,
  useEffect,
  useState,
} from 'react'
import Tooltip from './Tooltip'
import {
  alignItems,
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  boxShadow,
  classnames,
  display,
  fontWeight,
  justifyContent,
  opacity,
  padding,
  pointerEvents,
  textColor,
  transitionProperty,
  translate,
} from '../../tailwindcss-custom'
import { Spinner } from './Spinner'
import { EventContext } from '../toolbar/ContextProvider'

export type ButtonType = HTMLAttributes<HTMLElement> & {
  content: string | JSX.Element
  as?: ElementType
  autoFocus?: boolean
  isLoading?: boolean
  disabled?: boolean
  outline?: boolean
  dataTip?: string
  useDiv?: boolean
  useA?: boolean
  useSubmit?: boolean
  name?: string
  href?: string
  eventProperty?: Record<string, any> | undefined
  removeBg?: boolean
  removeStyle?: boolean
  nonEventName?: string
  eventAction?: boolean
}
let tip: string = ''
export const devMode = () => {
  tip = 'asd'
}
export function Button(props: ButtonType) {
  let {
    className,
    as,
    dataTip,
    outline,
    isLoading,
    useDiv,
    // href,
    useA,
    useSubmit,
    content,
    onClick,
    name,
    eventProperty,
    removeBg,
    removeStyle,
    nonEventName,
    eventAction,
    ...otherProps
  } = props

  const value = useContext(EventContext)

  const [hover, setHover] = useState(false)

  let tooltips = tip == '' ? dataTip : name

  nonEventName ? (tooltips = nonEventName) : null

  const baseButtonStyles = classnames(
    display('flex'),
    justifyContent('justify-center'),
    alignItems('items-center'),
    borderRadius('rounded-lg'),
    fontWeight('font-bold'),
    translate('hover:translate-y-0.5'),
    transitionProperty('transition-all'),
    padding('py-1', 'px-3')
  )

  const opaqueStyles = classnames(
    backgroundColor('bg-theme-green'),
    textColor('text-theme-dark-text'),
    opacity('hover:opacity-70')
  )

  const outlineStyles = classnames(
    backgroundColor('bg-transparent', 'hover:bg-theme-green'),
    boxShadow('shadow-inner'),
    borderColor('border-theme-green'),
    borderWidth('border'),
    textColor('text-theme-green', 'hover:text-theme-dark-text')
  )

  const buttonStyle = classnames(
    removeStyle ? null : baseButtonStyles,
    removeBg ? null : outline ? outlineStyles : opaqueStyles
  )

  let l = buttonStyle
  if (!removeBg && !outline) {
    l += ' bg-primary' //AVATECH PURPLE
  }
  l += ' ' + className

  if (!as) {
    if (useA) {
      as = 'a'

      // @ts-ignore
      otherProps.target = '_blank'
      // @ts-ignore
      otherProps.rel = 'noopener noreferrer'
      // otherProps.href=href
    } else if (useDiv) {
      as = 'div'
    } else {
      as = 'button'
    }
  }
  return (
    <Tooltip dataTip={tooltips}>
      {createElement(
        as,
        {
          id: name,
          className: l,
          onClick: (e: any) => {
            events(`${value}${eventAction ? '#' : '_'}${name}`, {
              eventProperty,
            })
            onClick && onClick(e)
          },
          ...otherProps,
        },
        isLoading ? <Spinner /> : content
      )}
    </Tooltip>
  )
}
